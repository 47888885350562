<template>
  <div class="gps-cars">
    <view-grid
      :tableCols="tableCols"
      :tableOps="tableOps"
      :query="query"
      :tableData="tableData"
      :total="total"
      :searchCols="searchCols"
      @callbackEvent="callbackEvent"
      @handleCurrentChange="handleCurrentChange"
    ></view-grid>
    <component
      @saveEvent="saveEvent"
      ref="refAnyCom"
      :formArray="formArray"
      :is="currentCopmonent"
      :row="curr_row"
      :menuList="menuList"
      :chk_top="chk_top"
      :dialogTitle="t('roles.Title')"
      :dialogWith="50"
    />
  </div>
</template>

<script setup>
import ViewGrid from "./components/ViewGrid.vue";
import {
  ref,
  defineAsyncComponent,
  watch,
  getCurrentInstance,
  shallowRef,
  reactive,
  onMounted,
  // markRaw,
  // toRef,
} from "vue";
// import {
//   Plus,
//   Delete,
//   BottomLeft,
//   TopRight,
//   Search,
//   Download,
//   Edit,
// } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import cars from "./RolesData";
import { ElNotification, ElMessage } from "element-plus";
const { t } = useI18n();
const refAnyCom = ref();
let { proxy } = getCurrentInstance();
//权限
const menuList = ref([]);
const chk_top = ref([]);

//动态组件
const activeKey = ref("GpsFormRole");
let currentCopmonent = shallowRef({});
currentCopmonent.value = defineAsyncComponent(() =>
  import(`./components/${activeKey.value}.vue`)
);
watch(
  () => activeKey.value,
  () => {
    currentCopmonent.value = defineAsyncComponent(() =>
      import(`./components/${activeKey.value}.vue`)
    );
  }
);

//表格显示的列
const tableCols = ref(cars.tableCols);
//表格操作列
const tableOps = ref(cars.tableOps);
//表单操作
const formArray = reactive(cars.formCols);
const row = {};
const curr_row = reactive(row);

const total = ref(0);

const handleSizeChange = (val) => {
  console.log(`${val} items per page`);
};
const handleCurrentChange = (val) => {
  query.page = val;
  getList();
};

//查询列
const searchCols = ref(cars.searchCols);

const tableData = ref([]);
const query = reactive({ rp: 25, page: 1 });

const getList = () => {
  proxy.$api.sys_role_grid(query).then((res) => {
    tableData.value = res.data.data;
    total.value = res.data.pagination.totalRecords;
  });
  // proxy.$api.item_get_company({}).then((res) => {
  //   let ay = res.data.data.map((it) => {
  //     it.name = it.cName;
  //     it.value = it.id;
  //     return it;
  //   });
  //   formArray[2].data = ay;
  // });
};
const init = () => {
  // proxy.$api.sys_get_bases({ flag: ["cllx", "sblx"] }).then((res) => {
  //   formArray[1].data = res.data.cllx;
  //   formArray[8].data = res.data.sblx;
  // });
  // let ay_channel = [];
  // for (let i = 0; i < 17; i++) {
  //   ay_channel.push({
  //     name: i.toString(),
  //     value: i.toString(),
  //   });
  // }
  // formArray[10].data = ay_channel;
  // //查询初始化
  proxy.$api.sys_init_datas({}).then((res) => {
    if (res.data.companys.length > 0) {
      let ay_companys = res.data.companys.map((it) => {
        return {
          name: it.cName,
          value: it.id,
        };
      });
      // console.log(searchCols);
      formArray[0].data = ay_companys;
    }
  });

  proxy.$api.menu_get_menu_list({}).then((res) => {
    if (res.data.list.length > 0) {
      menuList.value = res.data.list;
      // console.log();
    }
  });
};

onMounted(() => {
  getList();
  init();
});

const callbackEvent = (obj) => {
  switch (obj.type) {
    case "add":
    case "edit":
      if (obj.row) {
        chk_top.value = [];
        menuList.value.forEach((it) => {
          it.chk = [];
        });
        Object.assign(curr_row, obj.row);
        // console.log("edit", curr_row);
        proxy.$api
          .menu_get_menu_chk_by_rid({ rid: curr_row.id })
          .then((res) => {
            let chk_row = res.data.list;
            // console.log(chk_row);
            if (chk_row[0]) {
              chk_top.value = chk_row[0];
              // console.log(chk_top.value);
            }
            menuList.value.forEach((it) => {
              console.log(it.id);
              if (chk_row[it.id]) {
                it.chk = chk_row[it.id];
                console.log(it.chk);
              }
            });

            // console.log(res);
            activeKey.value = "GpsFormRole";
            refAnyCom.value.dialogVisible = true;
          });
      } else {
        // activeShow.value = true;
        activeKey.value = "GpsFormRole";
        refAnyCom.value.dialogVisible = true;
      }

      break;
    case "delete":
      proxy.$api.sys_role_delete({ ids: obj.row.id, t: 1 }).then((res) => {
        if (res.data.result == 1) {
          // proxy.$api
          //   .sys_orderdata_save({ ids: obj.row.id, t: 1 })
          //   .then((res_res) => {});
          ElNotification({
            title: t("commKey.MSG_TITLE"),
            message: t("commKey.DELETESUCCESS"),
            duration: 2000,
          });
        } else {
          ElNotification({
            title: t("commKey.MSG_TITLE"),
            message: t("commKey.DELETEFAIL"),
            duration: 2000,
          });
        }
        getList();
      });
      break;

    case "search":
      Object.assign(query, obj.search);
      getList();
      break;
  }
};
const saveEvent = (temp) => {
  // console.log(temp);
  if (temp.rName == "") {
    ElMessage({
      type: "error",
      message: t("map.mingchengbitian"),
    });
    return;
  }

  proxy.$api.sys_role_save(temp).then((res) => {
    ElNotification({
      title: t("commKey.MSG_TITLE"),
      message: t("commKey.MSG_" + res.data.msg_type),
      duration: 2000,
    });
    if (res.data.msg_type == 1 || res.data.msg_type == 2) {
      refAnyCom.value.dialogVisible = false;
      getList();
    }
  });
};
</script>

<style lang="scss" scoped></style>
